import AddIcon from '@mui/icons-material/Add';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Image from 'commons/Image';
import Text from 'commons/Text';

import useStyles from './style';

const SelectCollection = ({
  collection,
  optionCollection = [],
  setCollection,
  setIsShowDialog,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [openSelectCollection, setOpenSelectCollection] = useState(false);

  const handleChangeCollection = (e) => {
    const value = e.target.value;
    setCollection(value);
  };

  const handleSelectCollection = () => {
    setOpenSelectCollection(true);
  };

  const handleCloseCollection = () => {
    setOpenSelectCollection(false);
  };

  const handleOpenDialogCollection = () => {
    setOpenSelectCollection(false);
    setIsShowDialog(true);
  };

  return (
    <>
      <Text
        fontWeightBold
        type="lg"
        mb={0}
        mt={16}
      >
        {t('collection')}
      </Text>
      <FormControl
        className={classes.formControlStyle}
        variant="outlined"
      >
        <InputLabel>{t('select_a_collection')}</InputLabel>
        <Select
          value={collection}
          open={openSelectCollection}
          onChange={handleChangeCollection}
          onOpen={handleSelectCollection}
          onClose={handleCloseCollection}
          label={t('select_a_collection')}
          renderValue={(selected) => {
            const selectedOption = optionCollection.find((option) => option.id === selected);
            return selectedOption ? selectedOption.title : t('select_a_collection');
          }}
        >
          <MenuItem value="">
            <div className={classes.valueNone} />
          </MenuItem>
          {optionCollection && optionCollection.length > 0 ? (
            optionCollection.map((option, index) => (
              <MenuItem
                key={index}
                value={option.id}
              >
                {option?.image ? (
                  <Image
                    src={option?.image}
                    imageStyle={classes.image}
                  />
                ) : (
                  <div className={classes.imageCustom} />
                )}
                {option.title}
              </MenuItem>
            ))
          ) : (
            <MenuItem value="">{t('no_collection_available')}</MenuItem>
          )}
          <div>
            <div
              onClick={handleOpenDialogCollection}
              className={classes.wrapIcon}
            >
              <AddIcon className={classes.iconAdd} />
              <Text className={classes.textCreateLocation}>{t('create_new_collection')}</Text>
            </div>
          </div>
        </Select>
      </FormControl>
    </>
  );
};

export default SelectCollection;
