import ClearIcon from '@mui/icons-material/Clear';
import { Box, Checkbox, FormControl, Select } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import SearchInputIcon from 'commons/Search/SearchInputIcon';
import Text from 'commons/Text';
import { getLabelForOption } from 'configs/Constant';

import useStyles from './styles';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const SelectSubjects = ({
  selectedSubjects = [],
  dropdownSubject,
  handleCheckboxChange,
  handleDeleteItemSubject,
  fontWeightBold,
  typeText,
  mbText,
  mtText,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const language = useSelector((state) => state?.language?.currentLanguage);
  const [searchTerm, setSearchTerm] = useState('');

  const filteredSubjects = dropdownSubject.filter((item) =>
    getLabelForOption(item, language)?.toLowerCase()?.includes(searchTerm?.toLowerCase()),
  );

  return (
    <div>
      <Text
        fontWeightBold={fontWeightBold}
        type={typeText}
        mb={mbText}
        mt={mtText}
      >
        {t('subject')}
      </Text>
      <FormControl className={classes.formControlStyle}>
        <Select
          multiple
          MenuProps={MenuProps}
          displayEmpty
          value={selectedSubjects}
          renderValue={(selected) => {
            if (selected?.length === 0) {
              return <Text className={classes.textPlacehold}>{t('select_subject')}</Text>;
            }

            return (
              <Box className={classes.boxSelect}>
                {selected?.map((id) => {
                  const selectedSubject = dropdownSubject?.find((s) => s?.id === id);
                  return (
                    <div
                      key={selectedSubject?.id}
                      className={classes.wrapItemSelected}
                    >
                      <div className={classes.itemSelected}>
                        {getLabelForOption(selectedSubject, language)}
                      </div>
                      <ClearIcon
                        onMouseDown={(event) => {
                          event.stopPropagation();
                          handleDeleteItemSubject(id);
                        }}
                      />
                    </div>
                  );
                })}
              </Box>
            );
          }}
        >
          <div
            style={{ marginRight: 12, marginLeft: 12 }}
            className={classes.wrapSearch}
          >
            <SearchInputIcon
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder={t('search_subject')}
              fullWidth
            />
          </div>
          {filteredSubjects.map((item) => (
            <Box
              key={item.id}
              className={classes.boxDropdown}
              onMouseDown={() => handleCheckboxChange(item?.id)}
            >
              <Checkbox checked={selectedSubjects?.includes(item?.id)} />
              <Text>{getLabelForOption(item, language)}</Text>
            </Box>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default SelectSubjects;
