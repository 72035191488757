import { Box } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Breadcrumbs from 'commons/Breadcrumbs';
import Routes from 'utils/Route';

import BoxInfo from '../BoxInfo';
import BoxStatement from '../BoxStatement';
import useStyles from './styles';

const GalleryInfo = ({
  statement,
  defaultStatement,
  isOwner,
  userProfile,
  setStatement,
  setDefaultStatement,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const user = useSelector((state) => state.auth.account.user);

  const breadCrumbs = [
    {
      to: Routes.Home,
      text: t('home'),
    },
    {
      to: Routes.GalleryDetail(user?.uuid),
      text: t('gallery'),
      active: true,
    },
  ];

  return (
    <>
      <Breadcrumbs
        items={breadCrumbs}
        breadcrumbsStyle={classes.breadcrumbs}
      />

      <Box className={classes.wrapper}>
        <BoxInfo userProfile={userProfile} />
        <BoxStatement
          statement={statement}
          defaultStatement={defaultStatement}
          isOwner={isOwner}
          setStatement={setStatement}
          setDefaultStatement={setDefaultStatement}
        />
      </Box>
    </>
  );
};

export default GalleryInfo;
