import { Container } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useParams } from 'react-router-dom';

import API from 'configs/API';

import GalleryTabContent from './components/GalleryTabContent';
import GalleryWrapper from './components/GalleryWrapper';

const Gallery = () => {
  const { artistId } = useParams();

  const { data: userProfile, isPending } = useQuery({
    queryKey: [
      API.AUTH.PROFILE_INFO,
      {
        user_uuid: artistId,
      },
    ],
  });

  if (isPending) {
    return;
  }

  return (
    <Container sx={{ pb: 8 }}>
      <GalleryWrapper
        userProfile={userProfile}
        key={userProfile?.id}
      />
      <GalleryTabContent visibleSetting={userProfile?.visible_setting} />
    </Container>
  );
};

export default Gallery;
