import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import PinterestIcon from '@mui/icons-material/Pinterest';
import YouTubeIcon from '@mui/icons-material/YouTube';

import { ReactComponent as Tiktok } from 'images/tiktok-logo.svg';
import { capitalizeFirstLetter } from 'utils/utils';

export const CERTIFICATE = 'certificate';
export const SHOW = 'show';

export const TESTID = {
  WRAP_LOADING: 'WRAP_LOADING',
  INPUT_FIELD: 'INPUT_FIELD',
  INPUT_PHONE_NUMBER: 'INPUT_PHONE_NUMBER',
  INPUT_PASSWORD: 'INPUT_PASSWORD',
  BUTTON_LOGIN: 'BUTTON_LOGIN',
  BUTTON_LOGIN_PAGE_HOME: 'BUTTON_LOGIN_PAGE_HOME',
  BUTTON_SIGN_UP_PAGE_HOME: 'BUTTON_SIGN_UP_PAGE_HOME',
  BUTTON_SIGNUP: 'BUTTON_SIGNUP',
  MENU_PAPER_PROFILE: 'MENU_PAPER_PROFILE',
  ICON_BUTTON_SHOW_PASSWORD: 'ICON_BUTTON_SHOW_PASSWORD',
  LOGIN_BUTTON_FACEBOOK: 'LOGIN_BUTTON_FACEBOOK',
  WRAP_LAYOUT: 'WRAP_LAYOUT',
  HOME_PAGE: 'HOME_PAGE',
  ICON_BUTTON_PROFILE: 'ICON_BUTTON_PROFILE',
  WRAP_PRIVATE_LAYOUT: 'WRAP_PRIVATE_LAYOUT',
  LOGO: 'LOGO',
  INPUT_EMAIL_SIGNUP: 'INPUT_EMAIL_SIGNUP',
  INPUT_PHONE_SIGNUP: 'INPUT_PHONE_SIGNUP',
  INPUT_PASSWORD_SIGNUP: 'INPUT_PASSWORD_SIGNUP',
  INPUT_CONFIRM_PASSWORD_SIGNUP: 'INPUT_CONFIRM_PASSWORD_SIGNUP',
  AUTO_COMPLETE_CUSTOME: 'AUTO_COMPLETE_CUSTOME',
  TEXT_FIELD: 'TEXT_FIELD',
  DECIMAL_INPUT: 'DECIMAL_INPUT',
  INPUT_UPLOAD_IMAGE: 'INPUT_UPLOAD_IMAGE',
  SKELETON_IMAGE: 'SKELETON_IMAGE',
  BUTTON: 'BUTTON',
  UPLOAD_IMAGE: 'UPLOAD_IMAGE',
  POPOVER: 'POPOVER',
  HEADER_REDICRECT: 'HEADER_REDICRECT',
  SELECT_OPTION: 'SELECT_OPTION',
  BUTTON_UPDATE_ARTWORK: 'BUTTON_UPDATE_ARTWORK',
  BUTTON_CANCEL: 'BUTTON_CANCEL',
  HEADER_AVATAR_ICON: 'HEADER_AVATAR_ICON',
  MAIN_HEADER: 'MAIN_HEADER',

  // My account
  BUTTON_SAVE: 'BUTTON_SAVE',
  USER_AVATAR: 'USER_AVATAR',

  // My Gallery
  TAB_PANEL: 'TAB_PANEL',
  TEXT_GUIDE_ADD_STATEMENT: 'TEXT_GUIDE_ADD_STATEMENT',
  TEXT_STATEMENT: 'TEXT_STATEMENT',
  EDIT_ICON: 'EDIT_ICON',
  BOX_EDIT_STATEMENT: 'BOX_EDIT_STATEMENT',
  BUTTON_CANCEL_EDIT: 'BUTTON_CANCEL_EDIT',
  IMG_BANNER: 'IMG_BANNER',
  INPUT_UPLOAD_BANNER: 'INPUT_UPLOAD_BANNER',
  BUTTON_CANCEL_UPLOAD_BANNER: 'BUTTON_CANCEL_UPLOAD_BANNER',
  BUTTON_REMOVE_BANNER: 'BUTTON_REMOVE_BANNER',
  BUTTON_UPDATE_STATEMENT: 'BUTTON_UPDATE_STATEMENT',
  TEXT_AREA_STATEMENT: 'TEXT_AREA_STATEMENT',
  ERROR_TEXT_AREA_STATEMENT: 'ERROR_TEXT_AREA_STATEMENT',
  BOX_UPLOAD_ART_WORK: 'BOX_UPLOAD_ART_WORK',
  ART_WORK_ITEM: 'ART_WORK_ITEM',
  ART_WORK_ITEM_IMAGE: 'ART_WORK_ITEM_IMAGE',
  POPOVER_ARTWORK: 'POPOVER_ARTWORK',
  SPEDDIAL_COLLECTION_BUTTON: 'SPEDDIAL_COLLECTION_BUTTON',
  SPEDDIAL_COLLECTION_CONTENT: 'SPEDDIAL_COLLECTION_CONTENT',
  TEXT_SEEMORE_STATEMENT: 'TEXT_SEEMORE_STATEMENT',
  TEXT_ARTWORK_UPLOAD: 'TEXT_ARTWORK_UPLOAD',
  BUTTON_EDIT_PROFILE: 'BUTTON_EDIT_PROFILE',
  BUTTON_ARTWORK_ITEM_POPOVER: 'BUTTON_ARTWORK_ITEM_POPOVER',
  REQUEST_CERTIFICATE: 'REQUEST_CERTIFICATE',
  USER_PROFILE_SETTING_DOT: 'USER_PROFILE_SETTING_DOT',
  BUTTON_SETTING: 'BUTTON_SETTING',

  // Upload artwork
  REVIEW_IMAGE: 'REVIEW_IMAGE',
  FORM_INPUT_RADIO: 'FORM_INPUT_RADIO',
  BUTTON_ARTWORK: 'BUTTON_ARTWORK',
  CHECK_BOX_PERIOD_ARTWORK: 'CHECK_BOX_PERIOD_ARTWORK',
  FORM_INPUT_PERIOD_ARTWORK: 'FORM_INPUT_PERIOD_ARTWORK',
  EDITION_NUMBER_ARTWORK: 'EDITION_NUMBER_ARTWORK',
  ICON_REMOVE_IMAGE: 'ICON_REMOVE_IMAGE',

  // ArtworkDetail
  HEADER_LIST_ITEM: 'HEADER_LIST_ITEM',
  TEXT_PRICE: 'TEXT_PRICE',
  VIEW_IMAGE: 'VIEW_IMAGE',
  WRAP_ARTWORK_INFO_ATTRIBUTE: 'WRAP_ARTWORK_INFO_ATTRIBUTE',
  WRAP_IMAGE: 'WRAP_IMAGE',
  SOCIAL_ITEM: 'SOCIAL_ITEM',

  // EditArtistProfile
  ADD_ROW_INPUT: 'ADD_ROW_INPUT',
  AWARD_LIST_GRID: 'AWARD_LIST_GRID',
  YEAR_DESCRIPTION_INPUT: 'YEAR_DESCRIPTION_INPUT',
  UPLOAD_IMAGE_AWARD: 'UPLOAD_IMAGE_AWARD',
  EDIT_ICON_AWARD_LIST: 'EDIT_ICON_AWARD_LIST',
  EDIT_ICON_EXHIBITION_LIST: 'EDIT_ICON_EXHIBITION_LIST',
  EDIT_ICON_PUBLICATION_LIST: 'EDIT_ICON_PUBLICATION_LIST',
  CLOSE_ICON_PUBLICATION_LIST: 'CLOSE_ICON_PUBLICATION_LIST',
  CLOSE_ICON_AWARD_LIST: 'CLOSE_ICON_AWARD_LIST',
  CLOSE_ICON_EXHIBITION_LIST: 'CLOSE_ICON_EXHIBITION_LIST',
  REMOVE_ICON_IMAGE_AWARD: 'REMOVE_ICON_IMAGE_AWARD',
  FORM_INPUT_EXHIBITION_LINK: 'FORM_INPUT_EXHIBITION_LINK',
  DIALOG_BUTTON_DELETE_EXHIBITION: 'DIALOG_BUTTON_DELETE_EXHIBITION',
  DIALOG_BUTTON_DELETE_PUBLICATION: 'DIALOG_BUTTON_DELETE_PUBLICATION',
  DIALOG_BUTTON_DELETE_AWARD: 'DIALOG_BUTTON_DELETE_AWARD',
  IOS_SWITCH_EXHIBITION: 'IOS_SWITCH_EXHIBITION',
  IOS_SWITCH_PUBLICATION: 'IOS_SWITCH_PUBLICATION',
  BUTTON_ADD_EXHIBITION: 'BUTTON_ADD_EXHIBITION',
  BUTTON_ADD_PUBLICATION: 'BUTTON_ADD_PUBLICATION',
  BUTTON_ADD_ADWARD: 'BUTTON_ADD_ADWARD',
  FORM_INPUT_EDIT_ARTIST_PROFILE: 'FORM_INPUT_EDIT_ARTIST_PROFILE',

  // ArtistProfile
  IMAGE_AVATAR_PROFILE: 'IMAGE_AVATAR_PROFILE',

  //feature Certificate
  BUTTON_SIGNATURE: 'BUTTON_SIGNATURE',

  // ReviewCertificateRequest
  ARTIST_NAME: 'ARTIST_NAME',
  ARTWORK_TITLE: 'ARTWORK_TITLE',
  MEASUREMENT: 'MEASUREMENT',
  MEDIUM: 'MEDIUM',
  CREATION_YEAR: 'CREATION_YEAR',
  EDITION: 'EDITION',
  REJECT_MESSAGE: 'REJECT_MESSAGE',

  CURRENCY_OPTIONS: 'CURRENCY_OPTIONS',
  LIKE_COLLECTION_WRAPPER: 'LIKE_COLLECTION_WRAPPER',
};

export const USER_ROLE_BY_ID = {
  1: 'artist',
  2: 'Gallery owner',
  3: 'Service provider',
  4: 'Collector',
};

export const USER_ROLE_BY_KEY = {
  ARTIST: 1,
  GALLERY_OWNER: 2,
  SERVICE_PROVIDER: 3,
  COLLECTOR: 4,
};

export const MAX_CREATE_ARTWORK_STEPS = 2;

export const STATUS_CHOICES = {
  SOLD: 'sold',
  AVAILABLE: 'available',
  NOT_FOR_SALE: 'not_for_sale',
  LOST: 'lost',
  CONSIGNMENT: 'consignment',
  DONATED_GIFTED: 'donated_gifted',
  UNDER_MAINTENANCE: 'under_maintenance',
};

export const DateTimeFormat = {
  DD_MM_YYYY: 'DD/MM/YYYY',
  YYYY: 'YYYY',
};

export const OptionsCurrency = [
  { key: 'vnd', value: 'VND' },
  // { key: 'usd', value: 'USD' },
];

export const OPTIONS_DIMENSION_UNITS = [{ key: 'cm', value: 'cm' }];

export const CATEGORY_TYPE = {
  painting: 'painting',
  sculpture: 'sculpture',
};

export const SOCIAL_ICON = {
  FACEBOOK: FacebookRoundedIcon,
  INSTAGRAM: InstagramIcon,
  PINTEREST: PinterestIcon,
  YOUTUBE: YouTubeIcon,
  LINKEDIN: LinkedInIcon,
  TIKTOK: Tiktok,
};

export const SOCIAL_PLATFORM = Object.keys(SOCIAL_ICON).map((key) => capitalizeFirstLetter(key));

export const IMAGE_TYPE = { PDF: 'pdf' };

export const ALPHABET = [
  'ALL',
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
];

export const BREAKPOINTS = {
  mobile: '(max-width:600px)',
  laptop: '(min-width:1200px)',
};

export const ConfirmationOptions = [
  { id: 1, name: 'yes' },
  { id: 2, name: 'no' },
];

export const STATUS_CERTIFICATE_REQUEST_DISPLAY = {
  1: 'request_received',
  2: 'request_approved',
  3: 'request_denied',
  4: 'request_canceled',
};

export const STATUS_REQUEST_KEY = {
  REQUEST_RECEIVED: 1,
  REQUEST_APPROVED: 2,
  REQUEST_DENIED: 3,
  REQUEST_CANCELED: 4,
};

export const getLabelForOption = (option, language) => {
  switch (language) {
    case 'en':
      return option?.name;
    case 'vi':
      return option?.name_vi || option?.name || '';
    default:
      return option?.name;
  }
};

export const VisibilityStatusOption = [
  {
    key: 'text_upload_artwork_status_public',
    value: true,
  },
  {
    key: 'text_upload_artwork_status_private',
    value: false,
  },
];

export const isEditionStatusAvailable = (editions) =>
  editions?.some(
    (edition) =>
      edition.status === STATUS_CHOICES.AVAILABLE || edition.status === STATUS_CHOICES.CONSIGNMENT,
  );

export const CHAT_USER_PARAMS = 'users';
