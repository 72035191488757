import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  formControlStyle: {
    '&.MuiFormControl-root': {
      width: '100%',
      marginTop: 8,
    },
    '& .MuiSelect-select': {
      padding: 11,
    },
  },
  textPlacehold: {
    color: Colors.Grey6,
  },
  boxSelect: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 4,
  },
  wrapItemSelected: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: `1px solid ${Colors.Grey3}`,
    padding: 4,
  },
  itemSelected: {
    marginRight: 3,
  },
  boxDropdown: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 3,
  },
  wrapSearch: { marginRight: 12, marginLeft: 12, backgroundColor: Colors.Grey2 },
}));

export default useStyles;
