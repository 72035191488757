import { Avatar, Box, Divider, IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Text from 'commons/Text';
import { TESTID } from 'configs/Constant';
import useRole from 'hooks/useRole';
import { logout } from 'redux/reducer/authSlice';
import Routes from 'utils/Route';

import packageJson from '../../../../package.json';
import useStyles from '../styles';

const MenuProfile = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const user = useSelector((state) => state.auth.account.user);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isArtist } = useRole();

  const handleLogOut = () => {
    dispatch(logout());
    navigate(Routes.Login);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickMyAccount = () => {
    navigate(Routes.MyAccount);
  };

  const handleClickMyGallery = () => {
    navigate(Routes.GalleryDetail(user?.uuid));
  };

  const handleClickArtistProfile = () => {
    navigate(Routes.ArtistProfile(user?.uuid));
  };

  const handleClickManageArtwork = () => {
    navigate(Routes.ManageArtwork);
  };

  return (
    <>
      <Box sx={{ ml: 3 }}>
        <Tooltip title={t('account_setting')}>
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ p: 0 }}
            testid={TESTID.ICON_BUTTON_PROFILE}
          >
            <Avatar
              className={classes.avatarProfile}
              src={user?.avatar}
              slotProps={{
                img: {
                  'data-testid': TESTID.HEADER_AVATAR_ICON,
                },
              }}
            />
          </IconButton>
        </Tooltip>
      </Box>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        classes={{
          paper: classes.menuPaper,
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        testid={TESTID.MENU_PAPER_PROFILE}
      >
        <div className={classes.wrapItemMenuProfile}>
          <div className={classes.textAlignItemProfile}>
            <Avatar
              className={classes.avatarItemMenuProfile}
              src={user?.avatar}
            />
            <div className={classes.textUserName}>{user?.name}</div>
            <div className={classes.textEmail}>{user?.email}</div>
          </div>
        </div>

        <Divider />
        <MenuItem
          onClick={handleClickMyAccount}
          className={classes.menuItem}
        >
          {t('my_account')}
        </MenuItem>
        <MenuItem
          onClick={handleClickMyGallery}
          className={classes.menuItem}
        >
          {t('my_gallery')}
        </MenuItem>
        {isArtist && (
          <MenuItem
            onClick={handleClickArtistProfile}
            className={classes.menuItem}
          >
            {t('artist_profile')}
          </MenuItem>
        )}
        <MenuItem
          onClick={handleClickManageArtwork}
          className={classes.menuItem}
        >
          {t('manage_storage')}
        </MenuItem>

        <Divider />

        <MenuItem
          onClick={handleLogOut}
          className={classes.menuItem}
        >
          {t('sign_out')}
        </MenuItem>
        <Text
          type={'2xs'}
          className={classes.textPlatformVersion}
        >
          {t('platform_version')} {packageJson.version}
        </Text>
      </Menu>
    </>
  );
};
export default MenuProfile;
