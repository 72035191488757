import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  formControlStyle: {
    '&.MuiFormControl-root': {
      width: '100%',
      marginTop: 8,
    },
  },
  valueNone: {
    height: 15,
  },
  wrapIcon: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  iconAdd: {
    '&.MuiSvgIcon-root': {
      width: 24,
      height: 24,
      marginLeft: 10,
    },
  },
  textCreateLocation: {
    fontSize: 16,
    marginLeft: 5,
    width: '100%',
  },
  image: {
    height: 32,
    width: 32,
    marginRight: 8,
  },
  imageCustom: {
    height: 32,
    width: 32,
    marginRight: 8,
    backgroundColor: Colors.Grey3,
  },
}));

export default useStyles;
